import { useState, useEffect } from 'react';
import interact from 'interactjs';
import $ from 'jquery';
import 'jquery.transit'
import PopupStart from './components/PopupStart';
import PopupFilm from './components/PopupFilm';
import PopupRecipe from './components/PopupRecipe';
import PopupGift from './components/PopupGift';
import PopupFairytale from './components/PopupFairytale';
import PopupDecoration from './components/PopupDecoration';
import PopupMusic from './components/PopupMusic';
import PopupMafia from './components/PopupMafia';
import PopupMafiaAlert from './components/PopupMafiaAlert';
import PopupMelody from './components/PopupMelody';
import PopupQuiz from './components/PopupQuiz';
import PopupGame from './components/PopupGame';
import PopupShop from './components/PopupShop';
import PopupBread from './components/PopupBread';
import PopupStartMusykalka from './components/PopupStartMusykalka';
import PopupStartKviz from './components/PopupStartKviz';

const App = () => {

  const [popup, setPopup] = useState('start');

  var timer = 0;
  var timerStart = 0;
  var game_flag = false;
  var remote_froze = false;
  var gr_pos = 0;
  var gr_width = 0;
  var game_width = 0;
  var game_height = 0;
  var limit = 0;
  var right_edge = 0;
  var life_start_count = 100;
  var life_counter = life_start_count;
  var snowman_counter = 0;
  var snc = 0;
  const ball_step = 50;
  const snowman_step = 12;
  const carrot_step = 25;

  const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    var windowW = $(window).width();

    var imgtype = 'desktop';
    if(windowW < 1000) imgtype = 'mob';
    var imagesCount = $('img[data-src]:not(.mob):not(.desktop)').length + $('img[data-src].'+imgtype).length;
    var imagesCounter = 0;
    $('img[data-src]:not(.mob):not(.desktop),img[data-src].'+imgtype).each(function(){
      $(this).attr('src',$(this).data('src'));
      $(this).bind('load', function() {
        imagesCounter++;
        if(imagesCounter == imagesCount){
          $('.loading').fadeOut(1000);
        }
      });
    });

    window.gsap.registerPlugin(window.InertiaPlugin);

    window.Draggable.create(".draggable", {
      bounds: $('.container'),
      edgeResistance: 0.65,
      type: "x,y",
      inertia: window.innerWidth > 1000 || !(!!window.MSInputMethodContext && !!document.documentMode),
      autoScroll: true,
      force3D: false,
      onDrag: (e) => {
        //$('.cursor').css({x: e.screenX - $('.cursor').width()*0.1, y: e.screenY - $('.cursor').height()*0.1});
        $('.cursor').css({x: e.clientX, y: e.clientY});
      }
    });

    if(window.innerWidth <= 1000){
      window.TweenMax.set(".draggable", { x: 0, y: -(225 / 750 * window.innerWidth) });
    }


    /*interact('.draggable')
      .draggable({
        inertia: {
          resistance: 10,
          smoothEndDuration: 300
        },
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: false
          })
        ],
        autoScroll: true,
        listeners: {
          move: dragMoveListener,
        }
      })
      .resizable({
        edges: { left: true, right: true, bottom: true, top: true }
      })

    function dragMoveListener (event) {
      var target = event.target
      var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
      var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
      target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)
    }

    window.dragMoveListener = dragMoveListener*/

    window.snowStorm.snowColor = '#f7f7f7';
    window.snowStorm.flakesMaxActive = 60
    window.snowStorm.flakesMax = 100;
    window.snowStorm.snowStick = false;
    window.snowStorm.flakeWidth = 16
    window.snowStorm.flakeHeight = 16
    window.snowStorm.className = 'snow'

    $('body').addClass('load');

    $(window).resize(function(){
      $('img[data-src].mob:not([src]),img[data-src].desktop:not([src])').each(function(){
        $(this).attr('src',$(this).data('src'));
      });
    });

    $(window).mousedown(function(){
      $('.cursor').addClass('mousedown');
    })

    $(window).mouseup(function(){
      $('.cursor').removeClass('mousedown');
    })

    $(window).mousemove(function(e){

      console.log(e)

      //if(e.target.className.indexOf('buttons-in') !== -1 || e.target.className.indexOf('cursor-in') !== -1 || e.target.className.indexOf('slick-arrow') !== -1 || e.target.className.indexOf('MuiSelect-select') !== -1 || e.target.className.indexOf('MuiMenuItem-root') !== -1){
      if(e.target.className.indexOf('buttons-in') !== -1 || e.target.className.indexOf('cursor-item') !== -1){
        $('.cursor').addClass('onbuttons');
      }
      else{
        $('.cursor').removeClass('onbuttons');
      }
      //$('.cursor').css({x: e.screenX - $('.cursor').width()*0.1, y: e.screenY - $('.cursor').height()*0.1});

      $('.cursor').css({x: e.clientX, y: e.clientY});

      if($(e.target).parents('.nocursor').length || $(e.target).hasClass('nocursor') || $(e.target).parents('.MuiModal-root').length){
        $('.cursor').addClass('hide');
      }
      else{
        $('.cursor').removeClass('hide');
      }

    });

    $('.elements[close-popup] .buttons-in').hover(
      function(){
        var id = $(this).parent().attr('close-popup');
        $('.close-popup'+id).addClass('active');
      },
      function(){
        $('.close-popup').removeClass('active');
      }
    );

    $('.buttons-in').click(function(){
      $('.cursor').removeClass('mousedown');
    });



    function setCookie(name, value, options = {}) {
      options = {
        path: '/',
      };

      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
      }

      let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

      for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
          updatedCookie += "=" + optionValue;
        }
      }
      document.cookie = updatedCookie;
    }

    $('.button-cookie').click(function(){
      setCookie('accept_cookie','1');
      $('.cookie').removeClass('active');
    });
    $('#button-in-mafia').click(function(){
      var buttonwrap = $(this).parent();
      if(buttonwrap.hasClass('disabled')) return;
      buttonwrap.addClass('disabled');
      $.ajax({
        url: "/ajax/record.php",
        method: "post",
        data: {
          "fio": $('#popup-mafia-form input[name="fio"]').val(),
          "email": $('#popup-mafia-form input[name="email"]').val(),
          "slot": parseInt($('#popup-mafia-form input[name="slot"]').val())+1
        },
        dataType: "json",
        success: function (data) {
          $('#popup-mafia-form .popup-mafia__form-text').html('<div class="popup-mafia__form-message">'+data.message+'</div>');
          buttonwrap.removeClass('disabled');
          if(data.success){
            buttonwrap.hide();
            $('#popup-mafia-form .popup-mafia__form-message').addClass('success');
          }
        },
        error: function () {
          $('#popup-mafia-form .popup-mafia__form-text').html('<div class="popup-mafia__form-message">Ошибка</div>');
          buttonwrap.removeClass('disabled');
        }
      });
    });

    function getBase64(file) {
     return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
     });
    }
    $('#fairytale-load-form input:file').change(function(){
      $(this).prev().html(this.files[0].name);
      getBase64(this.files[0]).then(
       data => $('#fairytale-load-form input[name="filestr"]').val(data)
      );
    });
    $('#fairytale-load').click(function(){
      var buttonwrap = $(this).parent();
      if(buttonwrap.hasClass('disabled')) return;
      buttonwrap.addClass('disabled');
      $.ajax({
        url: "/ajax/save-tale.php",
        method: "post",
        data: {
          "title": $('#fairytale-load-form input[name="title"]').val(),
          "firstname": $('#fairytale-load-form input[name="firstname"]').val(),
          "lastname": $('#fairytale-load-form input[name="lastname"]').val(),
          "email": $('#fairytale-load-form input[name="email"]').val(),
          "file": $('#fairytale-load-form input[name="filestr"]').val()
        },
        dataType: "json",
        success: function (data) {
          $('.popup-fairytale-loadblock__rules-bottom').html('<div class="popup-mafia__form-message">'+data.message+'</div>');
          buttonwrap.removeClass('disabled');
          if(data.success){
            buttonwrap.hide();
            $('.popup-fairytale-loadblock__rules-bottom .popup-mafia__form-message').addClass('success');
          }
        },
        error: function () {
          $('.popup-fairytale-loadblock__rules-bottom').html('<div class="popup-mafia__form-message">Ошибка</div>');
          buttonwrap.removeClass('disabled');
        }
      });
    });

    //game
    gr_pos = $('.popup-game__remotecontrol').position().left;
    gr_width = $('.popup-game__remotecontrol').width();
    game_width = $(window).width();
    game_height = $('.popup-game__game').height();
    limit = gr_width/3;
    right_edge = game_width - gr_width;
    $('#lifecounter').html(life_counter);
    $('#scounter').html(snowman_counter);

    function game_move(){
      if(!game_flag) return;
      if(snc === 0 || snc > 50){
        $('.popup-game__game').append('<div class="snowman" style="left:'+randomInteger(parseInt(limit),parseInt(game_width-limit))+'px;top: -'+game_height*0.2+'px"><span class="left"></span><span class="right"></span></div>');
        snc = 0;
      }
      if(snc == 15 || snc == 40){
        $('.popup-game__game').append('<div class="carrot" style="left:'+randomInteger(parseInt(limit),parseInt(game_width-limit))+'px;top: -'+game_height*0.1+'px"></div>');
      }
      snc++;
      $('.popup-game__game .snowman:not(.hit):not(.dead)').each(function(){
        var cur = $(this);
        var top = (parseInt(cur.css('top'))+snowman_step);
        cur.css('top',top+'px');
        if(top > game_height*0.9){
          cur.addClass('end');
        }
      });
      var carrot_counted = false;
      $('.popup-game__game .carrot:not(.hit)').each(function(){
        var cur = $(this);
        var top = (parseInt(cur.css('top'))+carrot_step);
        cur.css('top',top+'px');
        if(top > game_height*0.9){
          cur.addClass('end');
        }
        top = game_height - top;
        var bottom = top - cur.height();
        var left = parseInt(cur.css('left'));
        var right = left + cur.width();

        var remote = $('.popup-game__remotecontrol');
        var r_top = game_height - remote.position().top;
        var r_bottom = r_top - remote.height();
        var r_left = parseInt(remote.css('left'));
        var r_right = r_left + remote.width();

        if(top >= r_bottom && bottom <= r_top && right >= r_left && left <= r_right){
          cur.addClass('hit');
          if(!carrot_counted){
            life_counter--;
            $('#lifecounter').html(life_counter);
            carrot_counted = true;
            if(life_counter == 0){
              game_flag = false;
              clearInterval(timer);
              clearInterval(timerStart);
              $('.popup-game,.popup-game__restart').addClass('restart');
              $('.popup-game__game .carrot').remove();
              return false;
            }
          }
          $('.popup-game__circle.heart').addClass('count');
          setTimeout(function(){
            $('.popup-game__circle.heart').removeClass('count');
          },300);

          setTimeout(function(){
            cur.remove();
          },1000);
        }
      });
      var snowball_counted = false;
      $('.popup-game__game .snowball:not(.hit)').each(function(){
        var cur = $(this);
        var bottom = (parseInt(cur.css('bottom'))+ball_step);
        var top = bottom + cur.height();
        var left = parseInt(cur.css('left'));
        var right = left + cur.width();
        cur.css('bottom',bottom+'px');
        if(bottom > game_height){
          cur.addClass('end');
        }
        var snowman_counted = false;
        $('.popup-game__game .snowman:not(.end):not(.win):not(.hit):not(.dead)').each(function(){
          var s_cur = $(this);
          var s_top = game_height - parseInt(s_cur.css('top'));
          var s_bottom = s_top - s_cur.height();
          var s_left = parseInt(s_cur.css('left'));
          var s_right = s_left + s_cur.width();
          if(top >= s_bottom && bottom <= s_top && right >= s_left && left <= s_right){
            s_cur.addClass('hit');
            cur.addClass('hit');
            setTimeout(function(){
              s_cur.toggleClass('hit dead');
              setTimeout(function(){
                s_cur.remove();
              },1000);
            },1000);
            if(!snowball_counted && !snowman_counted){
              snowman_counter++;
              $('#scounter').html(snowman_counter);
              snowball_counted = true;
              snowman_counted = true;
              $('.popup-game__circle.snowman').addClass('count');
              setTimeout(function(){
                $('.popup-game__circle.snowman').removeClass('count');
              },300);
              return false;
            }
          }
        });
      });
      $('.popup-game__game .snowman.end').addClass('win').removeClass('end');
      $('.popup-game__game .snowball.end,.popup-game__game .snowball.hit,.popup-game__game .carrot.end').remove();
      if($('.popup-game__game .snowman.win').length){
        $('.popup-game__remotecontrol').addClass('froze');
        remote_froze = true;
        setTimeout(function(){
          $('.popup-game__remotecontrol').removeClass('froze');
          remote_froze = false;
          $('.popup-game__game .snowman.win').remove();
        },1000);
      }
    }
    function game_start(){
      gr_width = $('.popup-game__remotecontrol').width();
      if($(window).width() < 1000) game_width = $(window).width();
      else game_width = $('.popup-game__game').width();
      game_height = $('.popup-game__game').height();
      limit = gr_width/3;
      right_edge = game_width - gr_width;

      $('.popup-game__remotecontrol').css({'left': gr_pos+'px'});
      game_flag = true;
      timer = setInterval(game_move,100);
      setTimeout(function(){
        $('.popup-game__rules').removeClass('active');
      },3000);
    }
    function snowman(){
      $('.popup-game__game').append('<div class="snowman" style="left:'+randomInteger(parseInt(limit),parseInt(game_width-limit))+'px;top: -20%"><span class="left"></span><span class="right"></span></div>');
    }

    $(window).keydown(function(e) {
      if(!game_flag || remote_froze) return;
      switch (e.which) {
      case 32:
        $('.popup-game__game').append('<div class="snowball" style="left:'+(gr_pos + gr_width/2)+'px;bottom: -'+game_height*0.01+'px"></div>');
        e.preventDefault();
        break;
      case 37:
        if(gr_pos > -limit) gr_pos -= 15;
        break;
      case 39:
        if(gr_pos < right_edge+limit) gr_pos += 15;
        break;
      }
      $('.popup-game__remotecontrol').css({'left': gr_pos+'px'});

    });
    $('.popup-game__leftbutton').click(function(){
      if(!game_flag || remote_froze) return;
      if(gr_pos > -limit) gr_pos -= 15;
      $('.popup-game__remotecontrol').css({'left': gr_pos+'px'});
    });
    $('.popup-game__firebutton').click(function(){
      if(!game_flag || remote_froze) return;
      $('.popup-game__game').append('<div class="snowball" style="left:'+(gr_pos + gr_width/2)+'px;bottom: -'+game_height*0.01+'px"></div>');
    });
    $('.popup-game__rightbutton').click(function(){
      if(!game_flag || remote_froze) return;
      if(gr_pos < right_edge+limit) gr_pos += 15;
      $('.popup-game__remotecontrol').css({'left': gr_pos+'px'});
    });

    $('.popup-game .popup__close,.popup-game .popup__closelayer').click(function(){
      game_flag = false;
      $('.popup-game__rules').addClass('active');
      clearInterval(timer);
      clearInterval(timerStart);
      setPopup(null);
    });
    $('.popup-game__frame,.popup-game__game').click(function(e){
      if($(this).parents('.popup-game').hasClass('restart')){
        e.preventDefault();
        $('.popup-game,.popup-game__restart').removeClass('restart');
        life_counter = life_start_count;
        snowman_counter = 0;
        $('#lifecounter').html(life_counter);
        $('#scounter').html(snowman_counter);
        game_start();
      }
    });
    $('.button-in8').click(function(){
      setPopup('game');
      if(window.ym){window.ym(51203048,'reachGoal','prug')}
      if($('.popup-game__restart.restart').length){
        $('.popup-game').addClass('restart');
        $('.popup-game__rules').removeClass('active');
      }
      if(!game_flag && !$('.popup-game__wrapin.start.active').length && !$('.popup-game.restart').length) game_start();
    });


    $('.button-in-kviz').click(function(e){
      $('.popup-kviz').removeClass('active');
      setPopup('mafia');
    });
    $('.popup-kviz .popup__closelayer').click(function(e){
      $('.popup-kviz').removeClass('active');
    });


    $('.popup-startmusykalka .buttons-in').click(function(e){
      $('.popup-startmusykalka').removeClass('active');
      //window.open('https://quizplease.ru/corp-stream?h=c61a0d876a91e4', '_blank');
      setPopup('melody');
    });
    $('.popup-startmusykalka .popup__closelayer').click(function(e){
      $('.popup-startmusykalka').removeClass('active');
    });

    $('.popup-startkviz .buttons-in').click(function(e){
      $('.popup-startkviz').removeClass('active');
      setPopup('quiz');
    });
    $('.popup-startkviz .popup__closelayer').click(function(e){
      $('.popup-startkviz').removeClass('active');
    });


    $('.startGame').click(function(){
      game_start();
    });

    var birdTimer, bird_anim = false, bird = 1, bird_up = true, bird_count = $('.bird img').length - 1;
    $('.bird').mouseenter(function(e){
      e.preventDefault();
      if(!bird_anim){
        bird_anim = true;
        birdTimer = setInterval(function(){
          if(bird == 0) clearInterval(birdTimer);
          $('.bird img.active').removeClass('active');
          $('.bird img:eq('+bird+')').addClass('active');
          if(bird_up){
            bird++;
            if(bird == bird_count) bird_up = false;
          }
          else{
            bird--;
            if(bird == 0){
              bird_up = true;
              bird_anim = false;
            }
          }
        },500);
      }
    });
    var boxTimer, box_anim = false, box = 1,box_count = $('.box img').length;
    $('.box').mouseenter(function(e){
      e.preventDefault();
      if(!box_anim){
        box_anim = true;
        boxTimer = setInterval(function(){
          if(box == 0) clearInterval(boxTimer);
          $('.box img.active').removeClass('active');
          $('.box img:eq('+box+')').addClass('active');
          box++;
          if(box == box_count){
            box = 0;
            box_anim = false;
          }
        },500);
      }
      else{
        return;
      }
    });
    var bootTimer, boot_anim = false, boot = 1, boot_count = $('.boot img').length;
    $('.boot').mouseenter(function(e){
      e.preventDefault();
      if(!boot_anim){
        boot_anim = true;
        bootTimer = setInterval(function(){
          if(boot == 0) clearInterval(bootTimer);
          $('.boot img.active').removeClass('active');
          $('.boot img:eq('+boot+')').addClass('active');
          boot++;
          if(boot == boot_count){
            boot = 0;
            boot_anim = false;
          }
        },500);
      }
    });

    $('.popup__select__icon').click(function(){
      $(this).prev().prev().click();
    });
  },[]);

  const sd = (h, m) => {
    const Y = new Date().getFullYear()
    const M = new Date().getMonth();
    const D = new Date().getDate();

    return new Date(Y, M, D, h, m, 0).getTime()
  }

  const checkSd = (start, end) => {
    const now = new Date().getTime();

    if(now >= start && now <= end){
      return true;
    }
    return false;
  }

  const showMafia = () => {
    const d1 = checkSd(sd(1, 0), sd(10, 0))
    const d2 = checkSd(sd(11, 0), sd(11, 20))
    const d3 = checkSd(sd(12, 20), sd(12, 40))
    const d4 = checkSd(sd(13, 40), sd(14, 0))
    const d5 = checkSd(sd(15, 0), sd(15, 20))
    const d6 = checkSd(sd(16, 20), sd(16, 40))
    const d7 = checkSd(sd(17, 40), sd(18, 0))
    const d8 = checkSd(sd(19, 0), sd(19, 20))
    const d9 = checkSd(sd(20, 20), sd(20, 40))

    if(d1 || d2 || d3 || d4 || d5 || d6 || d7 || d8 || d9){
      return <a href="https://events.mafdrive.ru/" target="_blank" className="elements element9"><div className="buttons-in button-in1"></div><img data-src="/img/new/element9.png" className="buttons" /></a>
      //return <div className="elements element9"><div className="buttons-in button-in1" onClick={() => {setPopup('mafia-alert');if(window.ym){window.ym(51203048,'reachGoal','nespat')}}}></div><img data-src="/img/new/element9.png" className="buttons" /></div>
    }
    //return <a href="https://events.mafdrive.ru/" target="_blank" className="elements element9"><div className="buttons-in button-in1"></div><img data-src="/img/new/element9.png" className="buttons" /></a>

    return <div className="elements element9"><div className="buttons-in button-in1" onClick={() => {setPopup('mafia');if(window.ym){window.ym(51203048,'reachGoal','nespat')}}}></div><img data-src="/img/new/element9.png" className="buttons" /></div>
    
  }

  return (
    <>
      <div className="cursor">
        <img src="/img/cursor1.png" />
        <img src="/img/cursor3.png" />
        <img src="/img/cursor4.png" />
      </div>
      <div className="loading nocursor">
        <img src="/img/loading.gif" />
      </div>
      <div className="container">
        <div className="bg draggable">
          <a href="https://www.x5.ru/ru" target="_blank" className="logolink cursor-item"></a>
          <img data-src="/img/new/bg_new5.jpg" className="draggable-bg desktop" />
          <img data-src="/img/mob/bg.jpg" className="draggable-bg mob" />
          <img data-src="/img/new/element1.png" className="old elements element1" />
          <img data-src="/img/new/element2new.png" className="old elements element2" />
          <img data-src="/img/new/element4.png" className="old elements element4" />
          <img data-src="/img/new/element5.png" className="old elements element5" />
          <img data-src="/img/new/element3new.png" className="elements element3" />
          <img data-src="/img/new/element48.png" className="elements element48" />
          <div className="elements element49">
            <div className="buttons-in button-in49" onClick={() => {setPopup('shop');if(window.ym){window.ym(51203048,'reachGoal','any')}}}></div>
            <img data-src="/img/new/element49.png" className="buttons" />
          </div>
          <img data-src="/img/new/element50_2.png" className="elements element50" />
          <img data-src="/img/new/element6.png" className="elements element6" />
          <img data-src="/img/new/element7.png" className="elements element7" />
          <div className="date date2312">23 декабря</div>
          <img data-src="/img/new/element8.png" className="elements element8" />
          {showMafia()}
          <img data-src="/img/new/element10.png" className="elements element10" />
          <img data-src="/img/new/element11.png" className="elements element11" />
          <img data-src="/img/new/element12.png" className="elements element12" />
          <img data-src="/img/new/element13.png" className="elements element13" />
          <img data-src="/img/new/element14.png" className="elements element14" />
          <div className="date date1301">13 января</div>
          <img data-src="/img/new/element15.png" className="elements element15" />
          <div className="elements element16">
            <div
              className="buttons-in button-in2"
              onClick={() => {
                //setPopup('melody');
                window.open('https://quizplease.ru/corp-stream?h=c61a0d876a91e4', '_blank');
                if(window.ym){window.ym(51203048,'reachGoal','my-my')}
              }}></div>
            <img data-src="/img/new/element16.png" className="buttons" />
          </div>
          <img data-src="/img/new/element44.png" className="elements element44 close-popup close-popup44" />
          <img data-src="/img/new/element17.png" className="elements element17" />
          <img data-src="/img/new/element18.png" className="elements element18" />
          <img data-src="/img/new/element19.png" className="elements element19" />
          <img data-src="/img/new/element20new.png" className="elements element20" />
          <div className="elements element21"><div className="buttons-in button-in3" onClick={() => {setPopup('fairytale');if(window.ym){window.ym(51203048,'reachGoal','podslushat')}}}></div><img data-src="/img/new/element21.png" className="buttons" /></div>
          <img data-src="/img/new/element22.png" className="elements element22" />
          <div className="date date2012">20 января</div>
          <img data-src="/img/new/element23.png" className="elements element23" />
          <div className="elements element24">
            <div
              className="buttons-in button-in4"
              onClick={() => {
                //setPopup('quiz');
                window.open('https://quizplease.ru/corp-stream?h=c61a0cc4a1b1bc', '_blank');
                if(window.ym){window.ym(51203048,'reachGoal','eche')
              }}}
            >
            </div>
            <img data-src="/img/new/element24.png" className="buttons" />
          </div>
          <img data-src="/img/new/element29.png" className="elements element29 close-popup close-popup29" />
          <img data-src="/img/new/element25.png" className="elements element25" />
          <img data-src="/img/new/element26.png" className="elements element26" />
          <div className="elements element27"><div className="buttons-in button-in5" onClick={() => {setPopup('recipe');if(window.ym){window.ym(51203048,'reachGoal','nyrnut')}}}></div><img data-src="/img/new/element27.png" className="buttons" /></div>
          <img data-src="/img/new/element66.png" className="elements element66" />
          <img data-src="/img/new/element28.png" className="elements element28" />
          <img data-src="/img/new/element31.png" className="elements element31" />
          <img data-src="/img/new/element33.png" className="elements element33" />
          <div className="elements element34">
            <div className="buttons-in button-in6" onClick={() => {setPopup('decoration');if(window.ym){window.ym(51203048,'reachGoal','krashit')}}}></div>
            <img data-src="/img/new/element34.png" className="buttons" />
          </div>
          <img data-src="/img/new/element35.png" className="elements element35" />
          <div className="elements element36">
            <div className="buttons-in button-in7" onClick={() =>  {setPopup('music');if(window.ym){window.ym(51203048,'reachGoal','sluchat')}}}></div>
            <img data-src="/img/new/element36.png" className="buttons" />
          </div>
          <div className="elements element37">
            <div className="buttons-in button-in8"></div>
            <img data-src="/img/new/element37.png" className="buttons" />
          </div>
          <img data-src="/img/new/element51.png" className="elements element51" />
          <img data-src="/img/new/element52.png" className="elements element52" />
          <img data-src="/img/new/element53.png" className="elements element53" />
          <img data-src="/img/new/element54.png" className="elements element54" />
          <img data-src="/img/new/element55.png" className="elements element55" />
          <img data-src="/img/new/element56.png" className="elements element56" />
          <img data-src="/img/new/element56.png" className="elements element57" />
          <img data-src="/img/new/element58.png" className="elements element58" />
          <img data-src="/img/new/element59.png" className="elements element59" />
          <img data-src="/img/new/element60.png" className="elements element60" />
          <img data-src="/img/new/element61.png" className="elements element61" />
          <img data-src="/img/new/element62.png" className="elements element62" />
          <img data-src="/img/new/element63.png" className="elements element63" />
          <img data-src="/img/new/element64.png" className="elements element64" />
          <img data-src="/img/new/element65.png" className="elements element65" />
          <img data-src="/img/new/element38.png" className="elements element38" />
          <img data-src="/img/new/element39.png" className="elements element39" />
          <img data-src="/img/new/element40.png" className="elements element40" />
          <img data-src="/img/new/element43.png" className="elements element43" />
          <img data-src="/img/new/element41.png" className="elements element41" />
          <div className="elements element42">
            <div className="buttons-in button-in9" onClick={() => {setPopup('film');if(window.ym){window.ym(51203048,'reachGoal','chin')}}}></div>
            <img data-src="/img/new/element42.png" className="buttons" />
          </div>
          <img data-src="/img/new/element45.png" className="elements element45" />
          <div className="elements element46">
            <div className="buttons-in button-in46" onClick={() => {setPopup('gift');if(window.ym){window.ym(51203048,'reachGoal','krasivo')}}}></div>
            <img data-src="/img/new/element46.png" className="buttons" />
          </div>
          <div className="bird">
            <img data-src="/img/items/bird1.png" className="bird__i1 active" />
            <img data-src="/img/items/bird2.png" className="bird__i2" />
            <img data-src="/img/items/bird3.png" className="bird__i3" />
          </div>
          <div className="box">
            <img data-src="/img/items/box1.png" className="box__i1 active" />
            <img data-src="/img/items/box2.png" className="box__i2" />
            <img data-src="/img/items/box3.png" className="box__i3" />
          </div>
          <div className="boot">
            <img data-src="/img/items/boot1.png" className="boot__i1 active" />
            <img data-src="/img/items/boot2.png" className="boot__i2" />
            <img data-src="/img/items/boot3.png" className="boot__i3" />
          </div>


          <div className="footer">
            <div>
              {/*<div className="footer__logos">
                <a href="#"><img data-src="/img/footer/img1.png" className="footer__logo1" /></a>
                <a href="#"><img data-src="/img/footer/img2.png" className="footer__logo2" /></a>
                <a href="#"><img data-src="/img/footer/img3.png" className="footer__logo3" /></a>
                <a href="#"><img data-src="/img/footer/img4.png" className="footer__logo4" /></a>
                <a href="#"><img data-src="/img/footer/img5.png" className="footer__logo5" /></a>
                <a href="#"><img data-src="/img/footer/img6.png" className="footer__logo6" /></a>
                <a href="#"><img data-src="/img/footer/img7.png" className="footer__logo7" /></a>
                <a href="#"><img data-src="/img/footer/img8.png" className="footer__logo8" /></a>
              </div>*/}
              Возникла проблема с порталом?<br/>
              <a href="mailto:ny@happy.x5.ru">Напишите нам, и мы все исправим</a><br/><br/>

              2021 X5 Group. Все права защищены
            </div>
            <div>
              {/* Создано: <a href="">Braininglab</a> и <a href="">A M I O</a> */}
            </div>
          </div>
        </div>
        <div className="topmusic cursor-in" onClick={() => setPopup('music')}>
          <img data-src="/img/music/top_circle.png" className="topmusic__circle cursor-in" />
          <img data-src="/img/music/top_arrow.png" className="topmusic__arrow cursor-in" />
          <img data-src="/img/music/top_note1.png" className="topmusic__note1 cursor-in" />
          <img data-src="/img/music/top_note2.png" className="topmusic__note2 cursor-in" />
          <img data-src="/img/music/top_note3.png" className="topmusic__note3 cursor-in" />
        </div>
      </div>

      <div className="cookie nocursor">
        <img data-src="/img/cookie.png" className="cookie__bg desktop" />
        <img data-src="/img/mob/cookie.png" className="cookie__bg mob" />
        <div className="cookie__text">
          <div>
            Продолжая просматривать сайт Вы даете согласие <br/>
            на использование cookie-ф&nbsp;&nbsp;&nbsp;айлов и соглашаетесь с <br/>
            <a href="/policy" target="_blank" className="cursor-in">Политикой конф&nbsp;&nbsp;&nbsp;иденциальности</a>
          </div>
        </div>
        <div className="elements element-cookie">
          <div className="buttons-in button-cookie"></div>
          <img data-src="/img/cookie_button.png" className="buttons" />
        </div>
      </div>

      <PopupStart popup={popup} type="start" close={() => setPopup(null)}  />
      <PopupFilm popup={popup} type="film" close={() => setPopup(null)} />
      <PopupRecipe popup={popup} type="recipe" close={() => setPopup(null)} />
      <PopupGift popup={popup} type="gift" close={() => setPopup(null)} />
      <PopupFairytale popup={popup} type="fairytale" close={() => setPopup(null)} />
      <PopupDecoration popup={popup} type="decoration" close={() => setPopup(null)} />
      <PopupMusic popup={popup} type="music" close={() => setPopup(null)} />
      <PopupMafia popup={popup} type="mafia" close={() => setPopup(null)} />
      <PopupMafiaAlert popup={popup} type="mafia-alert" close={() => setPopup(null)} />
      <PopupMelody popup={popup} type="melody" close={() => setPopup(null)} />
      <PopupQuiz popup={popup} type="quiz" close={() => setPopup(null)} />
      <PopupGame popup={popup} type="game" close={() => setPopup(null)} />
      <PopupShop popup={popup} type="shop" close={() => setPopup(null)} />
      <PopupBread popup={popup} type="bread" close={() => setPopup(null)} />
      
      <PopupStartMusykalka popup={popup} type="startmusykalka" close={() => setPopup(null)} />
      <PopupStartKviz popup={popup} type="startkviz" close={() => setPopup(null)} />
    </>
  );
}

export default App;
