import { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import $ from 'jquery';

const PopupGame = ({popup, type, close}) => {
  const [started, setState] = useState(0);

  return(
    <div className={popup === type ? 'popup-window popup-game active nocursor' : 'popup-window popup-game nocursor'}>
      <div className="popup__closelayer"></div>
      <div className={started === 1 ? 'popup-window__in popup-game__in game' : 'popup-window__in popup-game__in'}>
        <div className={started === 0 ? 'popup__close cursor-in start' : 'popup__close cursor-in'}><img data-src="/img/game/close.png" /></div>
        <div className={started === 0 ? 'popup-game__wrap start' : 'popup-game__wrap'}>
          <div className={started === 0 ? 'popup-game__wrapin start active' : 'popup-game__wrapin start'}>
            <div className="popup-game__startlayer startGame" onClick={e => setState(1)}></div>
            <img className="popup-game__frame desktop" data-src="/img/game/frame.png" />
            <div className="popup-game__title">
              <span>Игрульки<i>-</i></span>
              <span>попрыгульки</span>
            </div>
            <div className="popup-game__game bgcolor"></div>
            <div className="popup-game__start">
              <div className="popup-game__start-title">
                Предстоит битва со снеговиками
                <span>Как сражаться...</span>
              </div>
              <div className="popup-game__start-content">
                <div className="popup-game__start-i1">
                  <img data-src="/img/game/shadow1.svg" className="shadow desktop" />
                  <img data-src="/img/game/shadow1_mob.svg" className="shadow mob" />
                  <img data-src="/img/game/buttons.png" className="item desktop" />
                  <img data-src="/img/game/buttons_mob.png" className="item mob" />
                  <div className="title">Управление</div>
                  <div className="text desktop">передвигайтесь влево-вправо <br/>и не забывайте атаковать <br/>по нажатию на “пробел”</div>
                  <div className="text mob">передвигайтесь влево-вправо <br/>и не забывайте атаковать <br/>по нажатию на “fire”</div>
                </div>
                <div className="popup-game__start-i2">
                  <img data-src="/img/game/shadow2.svg" className="shadow desktop" />
                  <img data-src="/img/game/shadow2_mob.svg" className="shadow mob" />
                  <img data-src="/img/game/snowmanfull.png" className="item" />
                  <div className="title">Враг</div>
                  <div className="text">снеговик очень боится <br/>быстролетящих снежков, <br/>но очень проворный. <br/>внимание!</div>
                </div>
                <div className="popup-game__start-i3">
                  <img data-src="/img/game/shadow3.svg" className="shadow desktop" />
                  <img data-src="/img/game/shadow3_mob.svg" className="shadow mob" />
                  <img data-src="/img/game/snowball_notrace.png" className="item" />
                  <div className="title">Наше оружие</div>
                  <div className="text">снежок обыкновенный <br/>калибр 7,62</div>
                </div>
                <div className="popup-game__start-i4">
                  <img data-src="/img/game/shadow4.svg" className="shadow desktop" />
                  <img data-src="/img/game/shadow4_mob.svg" className="shadow mob" />
                  <img data-src="/img/game/carrot.png" className="item" />
                  <div className="title">Вражеское <br/>оружие</div>
                  <div className="text">морковка с ноздрями</div>
                </div>
                <div className="popup-game__start-i5">
                  <img data-src="/img/game/shadow5.svg" className="shadow desktop" />
                  <img data-src="/img/game/shadow5_mob.svg" className="shadow mob" />
                  <img data-src="/img/game/icon_heart.png" className="item" />
                  <div className="num">100</div>
                  <div className="title">Берегите здоровье</div>
                  <div className="text">его много, но снеговиков <br/>тоже</div>
                </div>
                <div className="popup-game__start-i6">
                  <img data-src="/img/game/shadow6.svg" className="shadow desktop" />
                  <img data-src="/img/game/shadow6_mob.svg" className="shadow mob" />
                  <img data-src="/img/game/icon_snowman.png" className="item" />
                  <div className="num">56</div>
                  <div className="title">Чем больше- <br/>тем лучше</div>
                </div>
              </div>
              <div className="popup-game__button-wrapper">
                <div className="elements element-popup-game">
                  <div className="buttons-in button-in-game"></div>
                  <img data-src="/img/game/start_button.png" className="buttons desktop" />
                  <img data-src="/img/game/start_button_mob.png" className="buttons mob" />
                </div>
              </div>
            </div>
          </div>
          <div className={started === 1 ? 'popup-game__wrapin active' : 'popup-game__wrapin'}>
            <img className="popup-game__bg desktop" data-src="/img/game/bg.png" />
            <img className="popup-game__frame desktop" data-src="/img/game/frame.png" />
            <img className="popup-game__rules active desktop" data-src="/img/game/rules.png" />
            <div className="popup-game__title">
              <span>Игрульки<i>-</i></span>
              <span>попрыгульки</span>
            </div>
            <div className="popup-game__circle heart">
              <img data-src="/img/game/icon_heart.png" />
              <span id="lifecounter"></span>
            </div>
            <div className="popup-game__circle snowman">
              <img data-src="/img/game/icon_snowman.png" />
              <span id="scounter"></span>
            </div>
            <div className="popup-game__game">
              <div className="popup-game__restart">
                <span>Поздравляем! <br/>Тест по метанию снежков пройден!</span>
                <img className="popup-game__restart-button" data-src="/img/game/restart_button.png" />
              </div>
              <img className="popup-game__remotecontrol" data-src="/img/game/remote_control.png" />
            </div>
            <div className={started === 1 ? 'popup-game__buttons mob active' : 'popup-game__buttons mob'}>
              <img className="popup-game__leftbutton" data-src="/img/mob/leftbutton.png" />
              <img className="popup-game__firebutton" data-src="/img/mob/firebutton.png" />
              <img className="popup-game__rightbutton" data-src="/img/mob/rightbutton.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupGame;
