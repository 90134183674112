import { useEffect } from 'react';
import $ from 'jquery';

const PopupStart = ({popup, type, close}) => {
  const getCookie = (name) => {
    var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
  const closeEvent = () => {
    if(!getCookie('accept_cookie')){
      $('.cookie').addClass('active');
    }
    setTimeout(function(){
      //$('.popup-kviz').addClass('active');

      //$('.popup-startmusykalka').addClass('active'); //24 декабря
      //$('.popup-startkviz').addClass('active'); //14 января
    },5000);

    close();
  }

  useEffect(() => {
    $('body').addClass('active');
    $('.popup').click(function(){
      $('body').addClass('active');
    })
  },[]);

  return(
    <div className={popup === type ? 'popup popup-start active nocursor' : 'popup popup-start nocursor'} onClick={closeEvent}>
      <div className="popup__in">
        <img data-src="/img/popup_desktop.png" className="popup__view-img desktop" />
        <img data-src="/img/popup_new2.png" className="popup__view-img mob" />
        <div className="popup__close cursor-in">
          <img data-src="/img/close.png" />
        </div>
        <div className="popup__cursor">
          <img data-src="/img/cursor1.png" />
          <img data-src="/img/cursor3.png" />
        </div>
        <img className="popup__gif" data-src="/img/prn2.png"/>
        <div className="popup__view">
          <div className="popup__view-in">
            <img data-src="/img/simple4.jpg" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupStart;
