const PopupStartKviz = ({popup, type, close}) => {
  return(
    <div className={popup === type ? 'popup-window popup-hello active nocursor popup-startkviz' : 'popup-window popup-hello nocursor popup-startkviz'}>
      <div className="popup__closelayer" onClick={close}></div>
      <img className="popup-hello__img" src="/img/kviz/img.png"/>
      <img className="popup-kviz__popup p4" src="/img/kviz/start_kviz.png"/>
      <div className="elements popup-hello__element2">
        <div className="buttons-in popup-hello__button"></div>
        <img src="/img/kviz/button.png" className="buttons" />
      </div>
    </div>
  )
}

export default PopupStartKviz;
