import { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const PopupQuiz = ({popup, type, close}) => {
  const [value, setValue] = useState(0);
  const [state, setState] = useState(0);

  return(
    <div className={popup === type ? 'popup-window popup-quiz active nocursor' : 'popup-window popup-quiz nocursor'}>
      <div className="popup__closelayer" onClick={close}></div>
      <div className="popup-window__in popup-quiz__in">
        <div className="popup__close cursor-in" onClick={close} ><img data-src="/img/quiz/close.png" /></div>
        <div className="popup-mafia__bgs">
          <img className="active desktop" data-src="/img/quiz/start.png" />
          <img className="active mob" data-src="/img/mob/quiz.png" />
        </div>
        <div className="popup-quiz__wrap">
          <div className="popup-quiz__title">
            <span>Квиз</span>
          </div>
          <div className="popup-quiz__text">
            <div>
              «Квиз» - это отличный способ классно провести время и получить позитивные впечатления!
            </div>
            <div>
              Регистрируйтесь и играйте как вам нравится: индивидуально, командой с коллегами (команду регистрирует капитан) или с семьей (регистрируйте домашнюю команду самостоятельно).<br/>
            </div>
            <div>
            Все участники трёх команд-победителей получат подарочные сертиф&nbsp;&nbsp;&nbsp;икаты в «Пятёрочку»:<br/>1 место - 3000 рублей<br/>2 место - 2000 рублей<br/>3 место -  1000 рублей
            </div>
          </div>
          <div className={state == 0 ? 'popup-quiz__button-wrapper active' : 'popup-quiz__button-wrapper'}>
            <div className="elements element-popup-quiz">
              <a className="buttons-in button-in-quiz" href="https://quizplease.ru/corp-page?id=d6azS" target="_blank"></a>
              <img data-src="/img/quiz/button.png" className="buttons" />
            </div>
          </div>
          {/*<div className="popup-quiz__text popup-quiz__text_bottom">
            <div>
              *Обязательно укажите личную почту при записи на игру (корпоративная почта может отправить уведомление о начале игры в спам, увы).
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  )
}

export default PopupQuiz;
