import { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const PopupShop = ({popup, type, close}) => {

  return(
    <div className={popup === type ? 'popup-window popup-shop active nocursor' : 'popup-window popup-shop nocursor'}>
      <div className="popup__closelayer" onClick={close}></div>
      <div className="popup-window__in popup-shop__in">
        <div className="popup__close cursor-in" onClick={close} ><img data-src="/img/shop/close.png" /></div>
        <img className="popup-shop__bg desktop" data-src="/img/shop/bg.png" />
        <div className="popup-shop__wrap">
          <img className="popup-shop__title" data-src="/img/shop/title.png" />
          <div className="popup-shop__text">
            <div>
              Размещайте свои товары в&nbsp;Магазине Добра и покупайте товары ваших коллег!
            </div>
            <div>
              Все средства идут в благотворительный фонд «Линия&nbsp;жизни», который помогает больным детям.
            </div>
            <div class="red">
              Станьте для них Дедом Морозом!
            </div>
          </div>
          <div className="popup-shop__button-wrapper">
            <div className="elements element-popup-shop">
              <a className="buttons-in button-in-shop" href="https://happy.x5.ru/md" target="_blank"></a>
              <img data-src="/img/shop/button.png" className="buttons" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupShop;
