import { useState, useEffect, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import { Scrollbars } from 'react-custom-scrollbars';

const PopupDecoration = ({popup, type, close}) => {
  const sliderRef = useRef();
  const [value, setValue] = useState(0)
  const [width, setWidth] = useState(window.innerWidth);
  const [started, setState] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    beforeChange: (current, next) => {
      setValue(next);
    }
  };

  const select = [
    "«Вечная ёлка»",
    "«Натуральный декор»",
    "«Текстиль в интерьере»",
    "«Живые цветы»",
    "«Энергосберегающие лампы и свечи из воска»",
    "«Сервировка без пластика»",
    "«Экологичные подарки»"
  ];
  const content = [
    '<div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/1/1.jpg" alt="" /></div><div class="popup-decoration-dsc">Современное, экологичное и экономичное решение – искусственная елка или живая елка в кадке. Отдельное место занимают креативные елки, созданные из материалов, которые есть в каждом доме: бумаги, отрезов ткани, гирлянд и ниток.</div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/1/2.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/1/3.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/1/4.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/1/5.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/1/6.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/1/7.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/1/8.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/1/9.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/1/10.jpg" alt="" /></div>',
    '<div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/2/1.jpg" alt="" /></div><div class="popup-decoration-dsc">Прогуляйтесь в ближайший парк или лес – там уже есть всё для праздничного декора: шишки, жёлуди, сухие опавшие веточки, интересные плотные листочки, отвалившаяся древесная кора. После тщательной просушки эти материалы станут отличными элементами праздничных композиций и ёлочными украшениями.</div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/2/2.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/2/3.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/2/4.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/2/5.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/2/6.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/2/7.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/2/8.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/2/9.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/2/10.jpg" alt="" /></div>',
    '<div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/3/1.jpg" alt="" /></div><div class="popup-decoration-dsc">Скатерти и настольные дорожки из льна и хлопка сделают атмосферу действительно праздничной и роскошной. Добавьте тканевые салфетки, оберните цветочные горшки в тканевые драпировки, сделайте банты из лент – и ваш дом преобразится как в сказке!</div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/3/2.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/3/3.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/3/4.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/3/5.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/3/6.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/3/7.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/3/8.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/3/9.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/3/10.jpg" alt="" /></div>',
    '<div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/4/1.jpg" alt="" /></div><div class="popup-decoration-dsc">Традиционный новогодний цвет – все оттенки красного, поэтому цветущие орхидеи, цикламены, азалии, а также пуансеттия и остролист прекрасно дополнят праздник. Соберите собственную композицию из живых цветов в горшках, украсив её традиционными хвойными лапами, игрушками и серпантином.</div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/4/2.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/4/3.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/4/4.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/4/5.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/4/6.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/4/7.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/4/8.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/4/9.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/4/10.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/4/11.jpg" alt="" /></div>',
    '<div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/5/1.jpg" alt="" /></div><div class="popup-decoration-dsc">Выбирайте гирлянды с энергосберегающими лампами: они могут быть любых цветов, не нагреваются, и служат намного дольше обычных. А можно зажечь свечи из настоящего воска. Для создания сказочной атмосферы используйте сразу несколько видов освещения: на ёлке, в композициях, на столе и в прозрачных вазах или бокалах.</div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/5/2.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/5/3.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/5/4.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/5/5.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/5/6.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/5/7.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/5/8.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/5/9.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/5/10.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/5/11.jpg" alt="" /></div>',
    '<div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/6/1.jpg" alt="" /></div><div class="popup-decoration-dsc">Новый год – это торжество, лучше всего эту атмосферу поддерживает сервировка в керамической или стеклянной посуде, даже если затевается грандиозная вечеринка. Натуральные текстиль и украшения на столе – ваш вклад в поддержку природы.</div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/6/2.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/6/3.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/6/4.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/6/5.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/6/6.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/6/7.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/6/8.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/6/9.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/6/10.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/6/11.jpg" alt="" /></div>',
    '<div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/7/1.jpg" alt="" /></div><div class="popup-decoration-dsc">Самые актуальные подарки сегодня – это впечатления, ведь они остаются с нами на всю жизнь. Например, билеты в кино, мастер-классы, экскурсии, приключения, посещения спа-салона или полёт на воздушном шаре. А если все же хочется преподнести сувенир, пусть это будет что-то, сделанное своими руками.</div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/7/2.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/7/3.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/7/4.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/7/5.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/7/6.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/7/7.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/7/8.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/7/9.jpg" alt="" /></div><div class="popup-decoration-big-img reverse"><img data-src="/img/decoration/photo/7/10.jpg" alt="" /></div><div class="popup-decoration-big-img"><img data-src="/img/decoration/photo/7/11.jpg" alt="" /></div>'
  ];

  const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const closeEvent = () => {
    let n = randomInteger(0,select.length-1);
    setValue(n);
    sliderRef.current.slickGoTo(n);
    close();
  };
  const changeEvent = (n) => {
    setValue(n);
    sliderRef.current.slickGoTo(n);
  };

  const getHeight = () => {
    let h = 975;
    if(width <= 1680){
      h = 975 / 1680 * window.innerWidth
    }
    else if(width <= 1000){
      h = 975 / 1000 * window.innerWidth
    }

    return h;
  }

  return(
    <div className={popup === type ? 'popup-window popup-decoration active nocursor' : 'popup-window popup-decoration nocursor'}>
      <div className="popup__closelayer" onClick={closeEvent}></div>
      <div className="popup-window__in popup-decoration__in">
        <div className="popup__close cursor-in" onClick={closeEvent} ><img data-src="/img/decoration/close.png" /></div>
        <div className={started == 0 ? 'popup-gift__bgs start' : 'popup-gift__bgs'}>
          <img className={started == 0 ? 'active desktop' : 'desktop'} data-src="/img/decoration/start.png" />
          <img className={started == 1 ? 'active desktop' : 'desktop'} data-src="/img/decoration/bg.png" />
        </div>
        <div className={started === 0 ? 'popup-state-start active' : 'popup-state-start'}>
          <div className="popup-decoration-start__title">
            <span>Как</span>
            <span>украсить квартиру</span>
            <span>к Новому году</span>
          </div>
          <div className="popup-decoration-start__text">
            <div>
              Отпраздновать и позаботиться об экологии? Легко!
            </div>
            <div>
              Создаём креативный новогодний декор с заботой о природе.
            </div>
          </div>
          <div className="popup-decoration__button-wrapper">
            <div className="elements element-popup-decoration">
              <div className="buttons-in button-in-decoration" onClick={e => setState(1)}></div>
              <img data-src="/img/decoration/start_button.png" className="buttons" />
            </div>
          </div>
        </div>
        <div className={started === 1 ? 'popup-state-list active' : 'popup-state-list'}>
          <div className={started === 1 ? 'popup-wrap__container active' : 'popup-wrap__container'}>
            <div className="popup-decoration-title">
              Украшаем<br/><span>квартиру</span>
            </div>
            <div className="popup__select">
              <Select
                value={value}
                onChange={e => changeEvent(e.target.value)}
                IconComponent={() => <div className="popup__select__icon"></div>}
              >
                {select.map((item, index) => <MenuItem key={`item${index}`} value={index}>{item}</MenuItem>)}
              </Select>
            </div>
            <Slider {...settings} ref={sliderRef} className="popup-decoration__slider">
              {content.map((item, index) => {return (
                <div key={`item${index}`}>
                  <div className="popup-decoration-slide">
                    <Scrollbars style={{ height: getHeight() }}>
                      <div className="popup-decoration-slide__in" dangerouslySetInnerHTML={{__html: item}} />
                    </Scrollbars>
                  </div>
                </div>
              )})}
            </Slider>
          </div>
        </div>
        <div className="popup-decoration__imgbottom mob">
          <img data-src="/img/mob/decoration.jpg" />
        </div>
      </div>
    </div>
  )
}

export default PopupDecoration;
