import { useState, useEffect, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";

const PopupGift = ({popup, type, close}) => {
  const sliderRef = useRef();
  const [value, setValue] = useState(0)
  const [width, setWidth] = useState(window.innerWidth);
  const [started, setState] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    beforeChange: (current, next) => {
      setValue(next);
    }
  };

  const select = [
    "бесф&nbsp;&nbsp;&nbsp;орменный подарок",
    "коробка или книжка",
    "подарочный пакет",
    "бант на подарке",
    "подарок с \"кармашками\"",
    "подарок своими руками"
  ];
  const gifts = [
    ["Как упаковать бесф&nbsp;&nbsp;&nbsp;орменный подарок","xBPTECMyrTU","1.jpg"],
    ["Как упаковать <br/>коробку или книжку","BFD7P7rLX9s?list=PLEden_Mvw4fQFiOfZ-EE4EUdlYdo5GaOe","2.jpg"],
    ["Как сделать <br/>подарочный пакет","lsablxleQqo","3.jpg"],
    ["Как завязать <br/>бант на подарке","rl_e93imN7U","4.jpg"],
    ["Как упаковать <br/>подарок с \"кармашками\"","V_2aTvgn1D0","5.jpg"],
    ["Идеи новогодних подарков своими руками","nv1KEmt3Vzs","6.jpg"]
  ];

  const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const closeEvent = () => {
    let n = randomInteger(0,select.length-1);
    setValue(n);
    sliderRef.current.slickGoTo(n);
    close();
  };
  const changeEvent = (n) => {
    setValue(n);
    sliderRef.current.slickGoTo(n);
  };
  useEffect(() => {
    $('.popup-gift-video-block .popup__close,.popup-gift-video-block__closelayer').click(function(){
      $('.popup-gift-video-block').removeClass('active');
      $('.popup-gift-video-block__frame iframe').attr('src','');
    });
    $('.popup-gift-video').click(function(){
      $('.popup-gift-video-block__frame iframe').attr('src','https://www.youtube.com/embed/'+$(this).data('href'));
      $('.popup-gift-video-block').addClass('active');
    });
  },[]);

  return(
    <div className={popup === type ? 'popup-window popup-gift active nocursor' : 'popup-window popup-gift nocursor'}>
      <div className="popup-gift-video-block">
        <div className="popup-gift-video-block__closelayer"></div>
        <div className="popup-gift-video-block__in">
          <div className="popup__close cursor-in"><img data-src="/img/gift/close.png" /></div>
          <div className="popup-gift-video-block__frame">
            <iframe width="560" height="315" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div className="popup__closelayer" onClick={closeEvent}></div>
      <div className="popup-window__in popup-gift__in">
        <div className="popup__close cursor-in" onClick={closeEvent} ><img data-src="/img/gift/close.png" /></div>
        <div className={started == 0 ? 'popup-gift__bgs start' : 'popup-gift__bgs'}>
          <img className={started == 0 ? 'active desktop' : 'desktop'} data-src="/img/gift/start.png" />
          <img className={started == 1 ? 'active desktop' : 'desktop'} data-src="/img/gift/bg.png" />
          <img className="active mob" data-src="/img/mob/gift1.png" />
        </div>
        <div className={started === 0 ? 'popup-state-start active' : 'popup-state-start'}>
          <div className="popup-gift-start__title">
            <span>Советы</span>
            <span>по упаковке</span>
            <span>подарков</span>
          </div>
          <div className="popup-gift-start__text">
            <div>
              Дарите красиво – с оригинальными идеями по упаковке подарков разной ф&nbsp;&nbsp;&nbsp;ормы и изготовлению милых сюрпризов своими руками.
            </div>
            <div>
              Повторяйте за нами и удивляйте друзей и близких.
            </div>
          </div>
          <div className="popup-gift__button-wrapper">
            <div className="elements element-popup-gift">
              <div className="buttons-in button-in-gift" onClick={e => setState(1)}></div>
              <img data-src="/img/gift/start_button.png" className="buttons" />
            </div>
          </div>
        </div>
        <div className={started === 1 ? 'popup-state-list active' : 'popup-state-list'}>
          <div className={started === 1 ? 'popup-wrap__container active' : 'popup-wrap__container'}>
            <div className="popup-gift-title">
              Как <br/><span className="line2">упаковать</span> <br/><span className="line3">подарок</span>
            </div>
            <div className="popup__select">
              <Select
                value={value}
                onChange={e => changeEvent(e.target.value)}
                IconComponent={() => <div className="popup__select__icon"></div>}
              >
                {select.map((item, index) => <MenuItem key={`item${index}`} value={index}><span dangerouslySetInnerHTML={{__html: item}} /></MenuItem>)}
              </Select>
            </div>
            <Slider {...settings} ref={sliderRef} className="popup-gift__slider">
              {gifts.map((item, index) => {return (
                <div key={`item${index}`}>
                  <div className="popup-gift-slide">
                    <div className="popup-gift-slide__in">
                      <div className="popup-gift__subtitle" dangerouslySetInnerHTML={{__html: item[0]}} />
                      <div data-href={item[1]} className="popup-gift-video">
                        <img data-src={`/img/gift/cover/${item[2]}`} alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              )})}
            </Slider>
          </div>
        </div>
        <div className="popup-gift__imgbottom mob">
          <img data-src="/img/mob/gift2.jpg" />
        </div>
      </div>
    </div>
  )
}

export default PopupGift;
