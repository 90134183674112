import { useState } from 'react';
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";

const PopupMusic = ({popup, type, close}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true
  };
  const frame = '<iframe frameborder="0" src="https://music.yandex.ru/iframe/#playlist/ki@braininglab.ru/1000">Слушайте <a href="https://music.yandex.ru/users/ki@braininglab.ru/playlists/1000">Новогодняя ярмарка</a> — <a href="https://music.yandex.ru/users/ki@braininglab.ru">Новогодняя ярмарка</a> на Яндекс.Музыке</iframe>';
  const link = 'https://music.yandex.ru/iframe/#playlist/ki@braininglab.ru/1000';
  const [value, setValue] = useState(1);
  const [frameval, setFrameValue] = useState(<div dangerouslySetInnerHTML={{__html: frame }} />);
  const stop = () => {
    close();
    setFrameValue(<div />);
    setTimeout(function(){
      setFrameValue(<div dangerouslySetInnerHTML={{__html: frame }} />);
    },50);
    $('.topmusic').removeClass('active');
  };
  const hide = () => {
    close();
    $('.topmusic').addClass('active');
  };

  return(
    <div className={popup === type ? 'popup-window popup-music active nocursor' : 'popup-window popup-music nocursor'}>
      <div className="popup__closelayer" onClick={stop}></div>
      <div className="popup-window__in popup-music__in">
        <div className="popup__hide cursor-in" onClick={hide} ><img data-src="/img/music/hide.png" /></div>
        <div className="popup__close cursor-in" onClick={stop} ><img data-src="/img/music/close.png" /></div>
        <img className="popup-music__bg desktop" data-src="/img/music/bg.png"/>
        <img className="popup-music__title" data-src="/img/music/title.png" />
        <div className="popup-music__text">* для прослушивания музыки нужно авторизоваться в сервисе Яндекс</div>
        <div className="popup-music__frame">
          {frameval}
        </div>
        <div className="popup-music__button">
          <div className="elements element-popup-music">
            <div className="buttons-in button-in-music" onClick={close}></div>
            <img data-src="/img/music/button.png" className="buttons" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupMusic;
