import { useState, useEffect, useRef } from 'react';
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";

const PopupFilm = ({popup, type, close}) => {
  const sliderRef = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true
  };
  const [started, setState] = useState(0);
  const films = [
    ['1.jpg','Щелкунчик и четыре королевства','Клара получает на Рождество необычный подарок и отправляется в увлекательное путешествие королевств, в котором живут маги, феи и волшебники. Сказочные декорации сменяют друг друга с необычной скоростью. Но, чтобы разгадать тайны, героине придется встретиться и с армией злых мышей во главе с Мышиным Королем.'],
    ['2.jpg','Рождественские хроники','Брат с сестрой Тедди и Кейт хотят доказать, что Санта существует, а ради этого они планируют снять его появление на видео. Однако в самый ответственный момент маленьким героям приходится спасать Рождество и отправляться в путешествие в мир сказочных эльфов и оленей.'],
    ['3.jpg','Один дома','Мальчик Кевин получает прекрасную возможность остаться одному, как он и хотел. Нет старшего брата, который устраивает всякие козни, нет суматошных родителей: все уехали в Париж. А Кевина нечаянно забыли дома. Мальчик наслаждается свалившейся на него свободой: он делает то, что было раньше запрещено. В доме гремит музыка, повсюду разбросаны игрушки. И вдруг посреди «праздника жизни» Кевин замечает за окном грабителей. Воришки, решившись поживиться чужим добром, и не подозревали, каким боком им все это выйдет…'],
    ['4.jpg','Пережить Рождество','У Дрю (Бен Аффлек) есть почти все, о чем может мечтать человек: любимая девушка, престижная работа, большая квартира и очень много денег. Единственное, чего ему на самом деле не хватает, – это семья и друзья. Опечаленный парень отправляется к дому, в котором он провел детство. Коттедж уже заселен новыми жильцами. И тогда богач решает арендовать дом… вместе с живущей там семьей. Но игра в традиционную счастливую семейку не получается. Ведь новая «родня» Дрю оказывается непредсказуемой и скандальной.'],
    ['5.jpg','Здравствуй, папа, Новый год! 2','Отец и отчим, которые являются противоположностью друг друга, соревнуются в том, чтобы устроить для детей лучшее Рождество в их жизни. Но тут на их голову сваливается еще и приезд их отцов - дедушек ребят. В итоге, праздник превращается в игру на выживание со всевозможными квестами и нелепыми ситуациями.'],
    ['6.jpg','Реальная любовь','Сплошная любовь, с легким юмором и неожиданными ситуациями. Премьер-министр Великобритании, по уши влюбленный в свою помощницу; молодой человек рвет свое сердце от безответной любви к жене своего лучшего друга; парень, который улетел самолетом в другую страну, чтобы найти единственную и неповторимую. Эти истории со счастливым концом не оставят вас равнодушными.'],
    ['7.jpg','Четыре Рождества','Брэд и Кейт встречаются уже три года. И, как ни странно, они оба являются противниками брака, видят в семейных узах ущемление личной свободы. Но их взгляды поменяются, когда они проведут целых четыре рождественских вечера в кругу родных и близких.'],
    ['8.jpg','Старый Новый год','Нью-Йорк – это город, который никогда не спит. Порой на его фоне судьбы людей причудливо переплетаются, в особенности накануне Нового года. Пожилой человек, секретарша, продюсер знаменитого шоу – что у них общего?'],
    ['9.jpg','Рождественские каникулы','В семействе Гризволдов – полный ералаш. Все готовятся к Рождеству. В этой суматохе главный герой пытается забыть, что накануне строгий босс лишил его рождественской премии. От досады все валится из рук. И рождественский вечер превращается в катастрофу. Валится елка с игрушками, появляются злые соседи и нежданные гости. А украшение стола – индюшка – лапками прилипает к потолку…'],
    ['10.jpg','Секретная служба Санта-Клауса','Пока Санта-Клаус вместе с эльфами доставляет подарки, его старший сын Стив Клаус проводит время в мечтательных грезах. Он уже видит себя в образе Санты. А его младший брат Артур работает в отделе писем и однажды обнаруживает письмо от девочки Гленн. Вышло так, что она не получит подарка. Чтобы исправить ошибку, юноша вместе с дедом на старых санях, запряженных оленями, отправляются к девочке Гленн.'],
    ['11.jpg','Отпуск по обмену','Айрис Симпкинс, автор популярной свадебной колонки, живет в английской провинции. Аманда Вудс, владелица процветающего рекламного агентства, ведет свое дело в Южной Калифорнии. Однажды, встретившись в интернете, они решили на время отпуска поменяться жильем.'],
    ['12.jpg','Привет семье!','Семья Стоунов по традиции приглашает отмечать Рождество всех своих пятерых детей и их вторых половинок. Но их отношение к доброму празднику меняется, когда любимый сын Эверетт приводит в дом девушку, которой все решили объявить бойкот.'],
    ['13.jpg','Санта-Клаус','Скотт Келвин недавно развелся с женой. На рождественские каникулы он приглашает сына, чтобы не потерять, а укрепить родственные связи. Убираясь по дому, Скотт обнаружил на крыше странного старика. Он попытался его согнать, чем чуть ли не до смерти напугал гостя. С последним случился сердечный приступ. К тому же гость оказался Санта-Клаусом, и теперь Скотту вместе с ним нужно спасать Рождество.'],
    ['14.jpg','Рождество с неудачниками','Кренки, как и все обычные семьи, по традиции готовятся к празднованию Рождества. Но Лютеру все это порядком надоело, и он решает вместе с Норой полететь на Карибы. Супруги собирают чемоданы, но вредный сосед поднял весь городок на ноги: как же можно нарушать общепринятые традиции?'],
    ['15.jpg','Семьянин','Когда-то Джон Кемпбел горячо любил Кейт. Но они расстались на целых 13 лет. Однажды Джону, уже преуспевающему бизнесмену, приносят записку от Кейт. Но он решает не ворошить прошлое и оставляет ее без внимания. Перед сочельником Джон заходит в небольшой магазинчик, где встречается с чернокожим грабителем. Он заключает с ним необычную сделку.'],
    ['16.jpg','Пока ты спал','Люси неожиданно спасает жизнь мужчины, в которого давно безответно влюблена. А затем происходит недоразумение: по ошибке героиню воспринимает как невесту молодого человека, а она не может этому сопротивляться. Накануне Рождество и девушке... придется встречать праздник с родственниками тайного возлюбленного. Но что будет, когда он выйдет из комы?'],
    ['17.jpg','Фред Клаус, брат Санты','Фред Санта – полный антипод своего брата Ника. Он не верит в чудеса, в Рождество, пока Ник не попадает в беду. В итоге Фред спасает брата, а также Рождество от уничтожения.'],
    ['18.jpg','Чудо на 34-й улице','Перед Рождеством 6-летняя Сюзан составляет список желаний. И девочка хочет не дорогие игрушки, а новый дом, папу и маленького братика. А когда героиня встречает Санту в крупном нью-йоркском универмаге «Коул», с ней и ее мамой начинают происходить самые настоящие чудеса. Неужели все мечты действительно сбудутся?'],
    ['19.jpg','Гринч – похититель Рождества','Жители сказочного Ктограда обожают праздновать Рождество. Но есть зеленое существо Гринч, которое ненавидит этот праздник. Еще в детстве он натерпелся обид от своих сверстников, которые дразнили его из-за необычной внешности. И однажды Гринч решил отомстить обидчикам – украсть праздник Рождество.'],
    ['20.jpg','Интуиция','Джонатан Трегер случайно знакомится с Сарой Томас: оба пытаются купить одну и ту же пару перчаток. Толком не зная друг друга, они проводят в суматошном предрождественском Нью-Йорке чудесный вечер. Встретятся ли они вновь? Подарит ли им судьба еще один шанс?'],
    ['21.jpg','Джек Фрост','История о музыканте, который пообещал своему сыну прийти на хоккейный матч с его участием, но не сделал этого. Он отправился на концерт. Ночью, возвращаясь домой в метель, музыкант попадает в аварию и погибает. Дух отца, который не выполнил своего обещания, превращается в Снеговика.'],
    ['22.jpg','Рождественская история','Ничто не могло изменить Эбенезера Скруджа, владельца долговой конторы. Он был бессердечным и злым человеком. Ради денег он не щадил никого. Но однажды в рождественскую ночь к нему пришли в гости три волшебных привидения, и свершилось чудо.'],
    ['23.jpg','Эльф','Мальчик из детского приюта Бадди Хоббс украдкой залез в мешок к Санта-Клаусу. И так получилось, что он стал приемным сыном одного из его эльфов. Бадди вырос и загорелся желанием найти родного отца. Каково же было удивление юноши, когда он все-таки нашел его. Оказалось, что отца волнует только бизнес.'],
    ['24.jpg','Бойф&nbsp;&nbsp;&nbsp;ренд на Рождество','Еще будучи маленькой девочкой Холли просит Санту подарить ей на Рождество хорошего парня. Но идут годы, а героиня по-прежнему проводит праздник с родителями. И только в этом году девушке, кажется, начинает везти. По крайней мере, она случайно встречает мужчину, который вполне мог бы стать ее возлюбленным.'],
    ['25.jpg','Семья напрокат','Сэм Филд – успешный бизнесмен и убежденный холостяк. Однажды он узнает, что очень влиятельный покупатель компании веско полагает, что Сэм – идеальный семьянин. Чтобы поддержать свой новый «имидж», он решил нанять актеров, которые бы сыграли роли его жены и сына. Но все вышло неудачно. Как-то в его дом внезапно вбегает семилетняя девочка Зоя. С этого момента жизнь Сэма Филда сильно меняется.'],
    ['26.jpg','Полярный экспресс','Мальчик упорно не верит в чудеса, он считает, что на свете нет никакого Санта-Клауса. Но мальчик вдруг получает от него персональное приглашение.'],
    ['27.jpg','Духи Рождества','После потери своего друга и партнера по бизнесу Эбенезер Скрудж впал в депрессию, которая сменилась ненавистью к окружающим людям и к работе. Что было бы дальше, неизвестно, не заявись к нему в рождественскую ночь духи Рождества.'],
    ['28.jpg','Кошмар перед Рождеством','Джек Скеллитон явно недоволен своим положением. Он – постоянный главный герой на празднике в городе Хеллоуин. Но ему надоело однообразие, одолела скука. И однажды он забрел на волшебную поляну за городом…'],
    ['29.jpg','Новая рождественская сказка','Телемагнат Френк Кросс никого не любит, кроме своих денег. Но однажды за его перевоспитание возьмутся необычные существа: призрак прошлого – лихой и веселый водила из ада, призрак настоящего – чертовски красивая фея и посланник будущего – без лица.'],
    ['30.jpg','Принцесса на Рождество','Накануне Рождества с Джулс случается череда неудач: ее увольняют с работы, машина ломается, на кредитной карте почти не остается денег, а тут еще и няня племянников, опекуном которых она является, сбегает от непослушных детей. Но тут как в сказке героиня получает приглашение провести праздники во дворце дальнего родственника. После недолгих раздумий девушка отправляется навстречу роскошной жизни и, конечно, любви с настоящем принцем.'],
    ['31.jpg','Добро пожаловать, или Соседям вход воспрещен','В маленький североамериканский городок одновременно приезжают две семьи. Глава одного из семейств Стив надеется провести здесь тихую и спокойную жизнь, но не таков Бадди Холл, его новый сосед. Он просто горит желанием испортить размеренный быт Стива.'],
    ['32.jpg','Подарок на Рождество','Так уж вышло, что Говард почти не уделял внимания своему маленькому сыну. А малыш очень хотел, чтобы папа подарил ему игрушку − супергероя Турбомена. В конце концов Говард решил загладить свою вину перед сыном.'],
    ['33.jpg','Жена священника','Ангел Дадли прилетел с одной целью: помочь священнику Генри Биггсу сохранить семью. Сам того не подозревая, Генри так увлекся работой, что не заметил трещины в семейных отношениях с женой Джулией.'],
    ['34.jpg','Ирония судьбы, или С легким паром!','Главный герой – москвич Женя Лукашин пошел (по давней традиции) с друзьями в баню, чтобы потом встретить Новый год со своей невестой Галей. В итоге затянувшейся вечеринки он очутился не в родной столице, а в Петербурге, в чужой квартире, где живет незнакомка Надя.'],
    ['35.jpg','Карнавальная ночь','Леночка Крылова вместе с друзьями решает поменять новогоднюю программу директора клуба, товарища Огурцова, который считает, что «главное – сурьезно» провести праздничный вечер. Чтобы спасти праздник от скуки, Леночка предлагает хитроумный план.'],
    ['36.jpg','Операция «С Новым годом!»','Перед самым Новым годом в травматологическое отделение больницы попадают самые разные пациенты: генерал Иволгин, милиционер с задержанным, который проглотил его медаль, а также женщины. Праздник уже на носу, и так хочется провести его весело!'],
    ['37.jpg','Ёлки','В первой части девочка Варя, живущая в калининградском детдоме, выдала себя за дочь президента. Ошеломленные этим известием дети просят Варю, чтобы «папа» поздравил ее с Новым годом какой-нибудь кодовой фразой. Девочка понимает, что миссия невыполнима, и решает сбежать.'],
    ['38.jpg','С Новым годом, мамы!','Пять разных историй, смешных и грустных, расскажут о главном – о любви к матери. И все истории разыграются накануне Нового года.'],
    ['39.jpg','Приходи на меня посмотреть','Татьяна, привлекательная женщина, живет с больной матерью Софьей Ивановной, которая очень переживает, что дочь останется старой девой. Однажды Софья Ивановна заявляет, что скоро умрет, поэтому Татьяна должна найти себе достойную пару. Перед Новым годом в дверь раздается стук. Татьяна открывает ее и видит на пороге незнакомого мужчину с букетом роз и шампанским.'],
    ['40.jpg','Морозко','Киносказка о доброй и красивой девочке Настеньке. С утра до вечера она трудится в доме, и ни разу не скажет ей хорошего слова мачеха. Мало того, она решает погубить Настеньку и отправляет ее в зимний лес. Но не знала злая женщина того, что на помощь девушке придут паренек Иванушка и дедушка Морозко.'],
    ['41.jpg','Эта веселая планета','На Землю прилетают инопланетяне и попадают на встречу Нового года. Молодой астроном пытается рассказать всем об этом великом событии. В конце концов он принимает за пришельца землянина и пытается наладить с ним контакт.'],
    ['42.jpg','Новогодние приключения Маши и Вити','Маша и Витя идут на выручку Снегурочке, которую похитил Кощей. Много трудностей придется им испытать, чтобы вызволить из плена Снегурочку.'],
    ['43.jpg','Снежная королева','Жестокая Снежная королева похищает Кая и превращает его сердце в холодную льдинку. Чтобы спасти его и вернуть домой, смелая Герда отправляется в далекий и опасный путь.'],
    ['44.jpg','Снегурочка','Прекрасный фильм-сказка о любви Снегурочки к земному парню Мизгирю. Ради любимого она пошла на великую жертву.'],
    ['45.jpg','Бедная Саша','Талантливый взломщик Березкин выходит из тюрьмы на короткую побывку, чтобы добыть местному мафиози породистого щенка. С собой Березкин везет чужие деньги, которые вскоре теряет. Чтобы исправить ситуацию, взломщик вместе с Аристархом (новым другом) решает ограбить банкиршу.'],
    ['46.jpg','Сирота казанская','Сельская учительница Настя ищет отца. Однажды она отправляет в редакцию газеты неопубликованное письмо матери. И перед самым Новым годом в доме Насти появляются отцы – сразу трое.'],
    ['47.jpg','Чародеи','Иван Пухов, музыкант и настройщик роялей, страстно любит привлекательную девушку Алену, которая трудится ведьмой в Институте волшебства. Вот-вот должна состояться свадьба молодых людей. И здесь в события вмешивается коварный Аполлон Сатанеев, заместитель директора института.'],
    ['48.jpg','Zолушка','Маша Крапивина приезжает из Липецка в Москву, поступает в институт и устраивается горничной в богатый дом. В новой семье ей несладко, но Маша горит желанием попасть на вечеринку Алексея Королевича. Девушка тайно в него влюблена. Хозяйская семья собирается на вечеринку. Неужели Маша останется дома?'],
    ['49.jpg','Джентльмены удачи','Фильм о том, как добрейшей души человек, заведующий детсадом Трошкин помог вернуть стране ее сокровище – золотой шлем Александра Македонского. И для этого ему пришлось побыть в шкуре матерого преступника по кличке Доцент.'],
    ['50.jpg','Тариф&nbsp;&nbsp;&nbsp; «Новогодний»','Фантастическая история любви. Парень и девушка знакомятся по телефону. Но все попытки встретиться неудачны. Оказывается, Алёна живет в 2008 году, а Андрей – в 2009-м. В канун Нового года с Аленой должна произойти страшная трагедия. И Андрей делает все возможное, чтобы это предотвратить...']
  ];

  const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const closeEvent = () => {
    sliderRef.current.slickGoTo(randomInteger(0,49));
    close();
  };

  return(
    <div className={popup === type ? 'popup-window popup-film active nocursor' : 'popup-window popup-film nocursor'}>
      <div className="popup__closelayer" onClick={closeEvent}></div>
      <div className="popup-window__in popup-film__in">
        <div className="popup__close cursor-in" onClick={closeEvent} ><img data-src="/img/film/close.png" /></div>
        <img className="popup-film__bg desktop" data-src="/img/film/bg.png"/>
        <img className="popup-film__bg mob" data-src="/img/mob/film2.jpg"/>
        <div className={started === 0 ? 'popup-state-start active' : 'popup-state-start'}>
          <div className="popup-film-start__title">
            <span>Подборка</span>
            <span>новогодних</span>
            <span>ф&nbsp;&nbsp;&nbsp;ильмов</span>
          </div>
          <div className="popup-film-start__text">
            <div>
              <div>
                Хо-хо-хо, <br/>
                ныряйте в праздничное <br/>
                настроение с лучшими <br/>
                новогодними ф&nbsp;&nbsp;&nbsp;ильмами!
              </div>
            </div>
            <div>
              <div>
                Выбирайте добрые, романтичные, <br/>
                сказочные и самые оптимистичные <br/>
                истории и пусть вся праздничная <br/>
                суета немножко подождёт.
              </div>
            </div>
          </div>
          <div className="popup-film__button-wrapper">
            <div className="elements element-popup-film">
              <div className="buttons-in button-in-film" onClick={e => setState(1)}></div>
              <img data-src="/img/film/start_button.png" className="buttons desktop" />
              <img data-src="/img/mob/filmbutton.png" className="buttons mob" />
            </div>
          </div>
          <div className="popup-film__imgbottom mob">
            <img data-src="/img/mob/film1.jpg" />
          </div>
        </div>
        <div className={started === 1 ? 'popup-state-list active' : 'popup-state-list'}>
          <div className="popup-film-title">КИНО</div>
          <Slider {...settings} ref={sliderRef} className="popup-film__slider">
            {films.map((item, index) => {return (
              <div key={`item${index}`}>
                <div className="popup-film__cover">
                  <img data-src={`/img/film/cover/${item[0]}`} alt=""/>
                </div>
                <div className="popup-film__subtitle" dangerouslySetInnerHTML={{__html: item[1]}} />
                <div className="popup-film__dsc" dangerouslySetInnerHTML={{__html: item[2]}} />
              </div>
            )})}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default PopupFilm;
