import { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const PopupMafia = ({popup, type, close}) => {
  const [value, setValue] = useState(0);
  const [state, setState] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true
  };
  const select = [
    "1 сессия 10:00 - 11:00",
    "2 сессия 11:20 - 12:20",
    "3 сессия 12:40 - 13:40",
    "4 сессия 14:00 - 15:00",
    "5 сессия 15:20 - 16:20",
    "6 сессия 16:40 - 17:40",
    "7 сессия 18:00 - 19:00",
    "8 сессия 19:20 - 20:20",
    "9 сессия 20:40 - 21:40",
  ];



  return(
    <div className={popup === type ? 'popup-window popup-mafia active nocursor' : 'popup-window popup-mafia nocursor'}>
      <div className="popup__closelayer" onClick={close}></div>
      <div className="popup-window__in popup-mafia__in">
        <div className="popup__close cursor-in" onClick={close} ><img data-src="/img/mafia/close.png" /></div>
        <div className="popup-mafia__bgs">
          <img className={state == 0 ? 'active desktop' : 'desktop'} data-src="/img/mafia/start.png" />
          <img className={state == 1 ? 'active desktop' : 'desktop'} data-src="/img/mafia/bg.png" />
        </div>
        <div className="popup-mafia__wrap">
          <div className="popup-mafia__title">
            <span>Маф&nbsp;&nbsp;&nbsp;ия</span>
          </div>
          <div className="popup-mafia__text">
            <div>Спасибо за игру!</div>
            <div>Поздравляем Вас с&nbsp;наступающим Новым&nbsp;годом!</div><br />
            {/*
            <div>
              Кто мирный житель, а кто маф&nbsp;&nbsp;&nbsp;ия?
            </div>
            <div className="red">
              Уже выясняем, игра началась!
            </div>
            <div>
            Если по какой-то причине Вы не смогли участвовать или хотите еще, то Вы можете присоединиться к&nbsp;любой сессии 23&nbsp;января. Просто приходите во время регистрации в&nbsp;комнаты:
            </div>
            <div className="mafia-small">
              <div>
              Регистрация 11:00-11:20<br />
              2 сессия 11:20 - 12:20
              </div>
              <div>
              Регистрация 12:20 - 12:40<br />
              3 сессия 12:40 - 13:40
              </div>
              <div>
              Регистрация 13:40-14:00<br />
              4 сессия 14:00 - 15:00
              </div>
              <div>
              Регистрация 15:00-15:20<br />
              5 сессия 15:20 - 16:20
              </div>
              <div>
              Регистрация 16:20-16:40<br />
              6 сессия 16:40 - 17:40
              </div>
              <div>
              Регистрация 17:40-18:00<br />
              7 сессия 18:00 - 19:00
              </div>
              <div>
              Регистрация 19:00-19:20<br />
              8 сессия 19:20 - 20:20
              </div>
              <div>
              Регистрация 20:20-20:40<br />
              9 сессия 20:40 - 21:40
              </div>
            </div>

            <div>Если ваша сеть подключена через vpn, то вам необходимо отключить vpn на время игр или добавить наш сайт в&nbsp;список <span className="underline">https://events.mafdrive.ru/</span></div><br />
            */}
          </div>
          <form method="post" className={state == 1 ? 'popup-mafia__form active' : 'popup-mafia__form'} id="popup-mafia-form">
            <div className="popup-mafia__form-line">
              <input type="text" name="email" placeholder="E-mail" />
              <div className="popup__select">
                <Select
                  name="slot"
                  value={value}
                  onChange={e => setValue(e.target.value)}
                  IconComponent={() => <div className="popup__select__icon"></div>}
                >
                  {select.map((item, index) => <MenuItem key={`item${index}`} value={index}>{item}</MenuItem>)}
                </Select>
              </div>
            </div>
            <div className="popup-mafia__form-line">
              <input type="text" name="fio" placeholder="ФИО" className="popup-mafia__form-fio" />
            </div>
            <div className="popup-mafia__form-text">
              Нажимая кнопку «зарегистрироваться», я даю <a href="/pdf/Soglasie_na_obrabotku_personal'nyh_dannyh_(Mafija).pdf" target="_blank" className="cursor-in">Согласие на обработку персональных данных</a> и соглашаюсь с <a href="/policy" target="_blank" className="cursor-in">Политикой конф&nbsp;&nbsp;&nbsp;иденциальности</a>
            </div>
            {/*<div className="popup-mafia__button-wrapper active">
              <div className="elements element-popup-mafia">
                <div className="buttons-in button-in-mafia" id="button-in-mafia"></div>
                <img data-src="/img/mafia/button.png" className="buttons" />
              </div>
            </div>*/}
          </form>
          <div className="popup-mafia__imgbottom mob">
            <img data-src="/img/mob/mafia.png" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupMafia;
