import { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const PopupMelody = ({popup, type, close}) => {
  const [value, setValue] = useState(0);
  const [state, setState] = useState(0);

  return(
    <div className={popup === type ? 'popup-window popup-melody active nocursor' : 'popup-window popup-melody nocursor'}>
      <div className="popup__closelayer" onClick={close}></div>
      <div className="popup-window__in popup-melody__in">
        <div className="popup__close cursor-in" onClick={close} ><img data-src="/img/melody/close.png" /></div>
        <div className="popup-melody__bgs">
          <img className="active desktop" data-src="/img/melody/start.png" />
          <img className="active mob" data-src="/img/mob/melody.png" />
        </div>
        <div className="popup-melody__wrap">
          <div className="popup-melody__title">
            <span>Угадай</span>
            <span>МЕЛОДИЮ</span>
          </div>
          <div className="popup-melody__text">
            <div>
              Самая музыкальная и веселая игра<br/>
              в мире ждёт своих<br/>
              победителей!
            </div>
            <div>
              Регистрируйтесь и играйте как вам нравится: индивидуально, командой с коллегами (команду регистрирует капитан) или c семьей (регистрируйте домашнюю команду самостоятельно).
            </div>
            <div>
              Все участники трёх команд-победителей получат подарочные сертиф&nbsp;&nbsp;&nbsp;икаты в «Пятёрочку»:<br/>1 место - 3000 рублей<br/>2 место - 2000 рублей<br/>3 место -  1000 рублей
            </div>
          </div>
          <div className={state == 0 ? 'popup-melody__button-wrapper active' : 'popup-melody__button-wrapper'}>
            <div className="elements element-popup-melody">
              <a className="buttons-in button-in-melody" href="https://quizplease.ru/corp-page?id=FQJnV" target="_blank"></a>
              <img data-src="/img/melody/button.png" className="buttons" />
            </div>
          </div>
          {/*<div className="popup-melody__text popup-melody__text_bottom">
            <div>
              *Обязательно укажите личную почту при записи на игру (корпоративная почта может отправить уведомление о начале игры в спам, увы).
            </div>
          </div>*/}
          <div className="popup-melody__imgbottom mob">
            <img data-src="/img/mob/melody2.png" />
          </div>

        </div>
      </div>
    </div>
  )
}

export default PopupMelody;
