const PopupBread = ({popup, type, close}) => {
  return(
    <div className={popup === type ? 'popup-window popup-kviz active nocursor' : 'popup-window popup-kviz nocursor'}>
      <div className="popup__closelayer" onClick={close}></div>
      <img className="popup-kviz__img" src="/img/kviz/img.png"/>
      <img className="popup-kviz__popup p2" src="/img/kviz/popup2.png"/>
      <div className="elements element-popup-kviz">
        <div className="buttons-in button-in-kviz"></div>
        <img src="/img/kviz/button.png" className="buttons" />
      </div>
    </div>
  )
}

export default PopupBread;
