const PopupMafiaAlert = ({popup, type, close}) => {
  return(
    <div className={popup === type ? 'popup-window popup-mafia-alert active nocursor' : 'popup-window popup-mafia-alert nocursor'}>
      <div className="popup__closelayer" onClick={close}></div>
      <div className="popup-window__in popup-mafia__in">
        <div className="popup__close cursor-in" onClick={close} ><img data-src="/img/mafia/close.png" /></div>
        <div className="popup-mafia__bgs">
          <img className="desktop active" src="/img/mafia/start.png" />
        </div>
        <div className="popup-mafia__wrap">
          <div className="popup-mafia__title">
            <span>Маф&nbsp;&nbsp;&nbsp;ия</span>
          </div>
          <div className="popup-mafia__text">
            <div>Если ваша сеть подключена через vpn, то вам необходимо отключить vpn на время игр или добавить наш сайт в&nbsp;список <a href="https://events.mafdrive.ru/" target="_blank" className="underline">https://events.mafdrive.ru/</a></div><br />
          </div>
          <a href="https://events.mafdrive.ru/" target="_blank" className="popup-mafia__button-wrapper active">
            <div className="elements element-popup-mafia">
              <div className="buttons-in button-in-mafia"></div>
              <img data-src="/img/tomafia.png" className="buttons" />
            </div>
          </a>
          <div className="popup-mafia__imgbottom mob">
            <img data-src="/img/mob/mafia.png" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupMafiaAlert;
